<template>
  <a-modal
    centered
    width="60vw"
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
    :body-style="{ height: '68vh', padding: 0 }"
  >
    <Monaco v-model="model" />
    <template slot="footer">
      <a-button @click="$reject()">取消</a-button>
      <a-button @click="onClear()">清空</a-button>
      <a-button type="primary" :disabled="!model" @click="onConfirm()">
        <i class="ndl-icon-search"></i>
        查询
      </a-button>
    </template>
  </a-modal>
</template>

<script>
export default {
  components: {
    Monaco: () => ({
      component: import(/* webpackChunkName: "monaco-sql" */ "./Monaco"),
      loading: {
        // eslint-disable-next-line no-unused-vars
        render(h) {
          return (
            <a-icon
              spin
              type="loading"
              class="ndl-flex ndl-flex--center ndl-flex--middle"
              style="height: 100%; font-size: 24px; color: #1890ff"
            />
          );
        }
      },
      delay: 0
    })
  },
  props: {
    sql: String,
    $resolve: Function,
    $reject: Function
  },
  data() {
    return {
      model: this.sql || ""
    };
  },
  methods: {
    onClear() {
      this.$resolve("");
    },
    onConfirm() {
      this.$resolve(this.model);
    }
  }
};
</script>
